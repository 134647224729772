import { useEffect, useRef, useState } from 'react'
import IconMinus from '@icons/Minus'
import IconPlusBig from '@icons/PlusBig'
import * as S from './ReadMoreReadLess.styled'

const ReadMoreReadLess = ({ content, isReactComponent, minLines, style }) => {
  if (!content) return null

  const [maxHeight, setMaxHeight] = useState(0)
  const [minHeight, setMinHeight] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isFinishedAnimating, setFinishedAnimating] = useState(false)
  const descriptionRef = useRef(null)

  const toggleDescription = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }

  useEffect(() => {
    const descriptionElement = descriptionRef.current

    if (descriptionElement && !isFinishedAnimating) {
      setFinishedAnimating(true)
      setMaxHeight(descriptionElement.scrollHeight + 1000)

      setTimeout(() => {
        setMinHeight(descriptionElement.clientHeight)
      }, 100)
    }

    return () => {
      // Clean up timeout if the component unmounts
      clearTimeout()
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      setFinishedAnimating(false)
    } else {
      setTimeout(() => {
        setFinishedAnimating(true)
      }, 500)
    }
  }, [isOpen])

  const shouldShowToggleButton = maxHeight > minHeight

  return (
    <S.ContentWrap style={style}>
      <S.Content
        ref={descriptionRef}
        visible={minHeight > 0}
        isOpen={isOpen}
        isFinishedAnimating={isFinishedAnimating}
        style={{ maxHeight: isOpen ? maxHeight : minHeight || null }}
        minLines={minLines || 5}
      >
        {isReactComponent ? (
          content
        ) : (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </S.Content>
      {shouldShowToggleButton && (
        <S.ReadMoreLessButton onClick={toggleDescription} open={isOpen}>
          {isOpen ? (
            <>
              Read less <IconMinus />
            </>
          ) : (
            <>
              Read more <IconPlusBig />
            </>
          )}
        </S.ReadMoreLessButton>
      )}
    </S.ContentWrap>
  )
}

export default ReadMoreReadLess
